import revive_payload_client_4sVQNw7RlN from "/var/www/nuxt-v3/prod/releases/15/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/nuxt-v3/prod/releases/15/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/nuxt-v3/prod/releases/15/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/nuxt-v3/prod/releases/15/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/nuxt-v3/prod/releases/15/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/nuxt-v3/prod/releases/15/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/nuxt-v3/prod/releases/15/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/nuxt-v3/prod/releases/15/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W86eSGFqyZ from "/var/www/nuxt-v3/prod/releases/15/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import nuxt_plugin_03YjkxYbK5 from "/var/www/nuxt-v3/prod/releases/15/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/nuxt-v3/prod/releases/15/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import fetch_14DdzPiXUB from "/var/www/nuxt-v3/prod/releases/15/plugins/fetch.ts";
import maska_UHaKf2z1iQ from "/var/www/nuxt-v3/prod/releases/15/plugins/maska.ts";
import swiper_client_blbPZy93VS from "/var/www/nuxt-v3/prod/releases/15/plugins/swiper.client.ts";
import uaeventDirective_client_v3S3WoTRgd from "/var/www/nuxt-v3/prod/releases/15/plugins/uaeventDirective.client.ts";
import vee_validate_KcKlKmvCrJ from "/var/www/nuxt-v3/prod/releases/15/plugins/vee-validate.ts";
import vue_final_modal_pML93k5qcp from "/var/www/nuxt-v3/prod/releases/15/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W86eSGFqyZ,
  nuxt_plugin_03YjkxYbK5,
  chunk_reload_client_UciE0i6zes,
  fetch_14DdzPiXUB,
  maska_UHaKf2z1iQ,
  swiper_client_blbPZy93VS,
  uaeventDirective_client_v3S3WoTRgd,
  vee_validate_KcKlKmvCrJ,
  vue_final_modal_pML93k5qcp
]
import { default as index05ELEmWmsxMeta } from "/var/www/nuxt-v3/prod/releases/15/pages/index.vue?macro=true";
import { default as simulation_45pineldmoTg8quegMeta } from "/var/www/nuxt-v3/prod/releases/15/pages/simulateurs-immobilier/simulation-pinel.vue?macro=true";
import { default as simulation_45ptzlwQprzBcXHMeta } from "/var/www/nuxt-v3/prod/releases/15/pages/simulateurs-immobilier/simulation-ptz.vue?macro=true";
export default [
  {
    name: index05ELEmWmsxMeta?.name ?? "index",
    path: index05ELEmWmsxMeta?.path ?? "/",
    meta: index05ELEmWmsxMeta || {},
    alias: index05ELEmWmsxMeta?.alias || [],
    redirect: index05ELEmWmsxMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/15/pages/index.vue").then(m => m.default || m)
  },
  {
    name: simulation_45pineldmoTg8quegMeta?.name ?? "simulateurs-immobilier-simulation-pinel",
    path: simulation_45pineldmoTg8quegMeta?.path ?? "/simulateurs-immobilier/simulation-pinel",
    meta: simulation_45pineldmoTg8quegMeta || {},
    alias: simulation_45pineldmoTg8quegMeta?.alias || [],
    redirect: simulation_45pineldmoTg8quegMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/15/pages/simulateurs-immobilier/simulation-pinel.vue").then(m => m.default || m)
  },
  {
    name: simulation_45ptzlwQprzBcXHMeta?.name ?? "simulateurs-immobilier-simulation-ptz",
    path: simulation_45ptzlwQprzBcXHMeta?.path ?? "/simulateurs-immobilier/simulation-ptz",
    meta: simulation_45ptzlwQprzBcXHMeta || {},
    alias: simulation_45ptzlwQprzBcXHMeta?.alias || [],
    redirect: simulation_45ptzlwQprzBcXHMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/15/pages/simulateurs-immobilier/simulation-ptz.vue").then(m => m.default || m)
  }
]